import ImagesStyle from '@/Styles/images.js';
import {styled} from '@mui/material';

export const Fieldset = styled('div')(({theme}) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '1rem',
    width: '100%',

    [theme.breakpoints.up('md')]: {
        display: 'grid',
        gap: '2rem',
        gridTemplateAreas: `
            "b a"
            "b c"`,
        gridTemplateColumns: '1.5fr 1fr',
        gridTemplateRows: 'auto 1fr',
        alignItems: 'start',

        '& > div:first-of-type': {
            gridArea: 'a',
        },
        '& > *:nth-of-type(2)': {
            gridArea: 'b',
        },
        '& div:last-of-type': {
            gridArea: 'c',
        },
    },
}));

export const FieldsetImage = styled('img')(({theme}) => ({
    ...ImagesStyle.cover_center,
    [theme.breakpoints.down('md')]: {
        position: 'relative',
        left: '-24px',
        width: 'calc(100% + 48px)',
    },
}));
